import React, { useState } from "react"
import Img from "gatsby-image/withIEPolyfill"

const TrackRecordsReferences = ({ title, references }) => {

    let [currentCategory, setCurrentCategory] = useState('all');

    const toggleCurrentCategory = (category) => {
        currentCategory === category ? setCurrentCategory('all') : setCurrentCategory(category);
    }

    return (
        <section className="common-wrapper overflow-hidden px-4 py-20 py-10-md">
            <header className="pb-30 pb-10-md px-40 px-0-md">
                <div className="flex justify-between items-center">
                    <h4 className="f1 text-blue-dark font-bold m-0">{title}</h4>
                </div>
                <div className="mt-10 block">
                    <span className="text-blue-dark font-normal f4 my-4 block">Filtrer par service</span>
                    <ul className="flex flex-col-md w-full text-align-center justify-evenly bs-solid bw-2 bc-blue-dark">
                        <li onClick={() => toggleCurrentCategory('cfo')} className={currentCategory === 'cfo' ? "w-33-per w-full-xs flex-grow-1 py-3 px-3 bg-blue-dark text-white cursor-pointer" : "w-full-xs w-33-per flex-grow-1 py-3 px-3 bg-grey-5 hover:bg-blue-dafinity hover:text-white cursor-pointer"}><span>CFO part-time</span></li>
                        <li onClick={() => toggleCurrentCategory('transaction-service')} className={currentCategory === 'transaction-service' ? "w-full-xs w-33-per flex-grow-1 py-3 px-3 bg-blue-dark text-white cursor-pointer" : "w-full-xs w-33-per flex-grow-1 py-3 px-3 bg-grey-5 hover:bg-blue-dafinity hover:text-white cursor-pointer"}><span>Transaction Services</span></li>
                        <li onClick={() => toggleCurrentCategory('transformation-digitale')} className={currentCategory === 'transformation-digitale' ? "w-full-xs w-33-per flex-grow-1 py-3 px-3 bg-blue-dark text-white cursor-pointer" : "w-full-xs w-33-per flex-grow-1 py-3 px-3 bg-grey-5 hover:bg-blue-dafinity hover:text-white cursor-pointer"}><span>RPA Services</span></li>
                    </ul>
                </div>
            </header>
            <ul className="flex justify-evenly flex-wrap">
                {
                    currentCategory === 'all' ?
                        references.map(reference => {
                            return (
                                <li key={reference.id} className="flex-col w-40-per-md w-full-xs flex-grow-1 w-20-per w-half-md bg-white py-6 px-4 mx-4 br-2 text-blue-dark shadow-1 my-5 max-width-300-px max-wdith-100-per-xs">
                                    <header className="flex flex-col items-center justify-center py-2">
                                        <p className="font-bold f4 text-blue-dark mt-0 mb-2">{reference.name}</p>
                                        <p className="font-normal f6 mt-1 mb-2">{reference.mission}</p>
                                        <div className="w-full h-40 flex justify-center items-center p-5 overflow-hidden mt-4">
                                            <a className="block w-full" target="_blank" href={reference.link ? reference.link : '/'}>
                                                {reference.logo && reference.logo.fluid &&
                                                    < Img
                                                        className="w-full"
                                                        fluid={reference.logo.fluid}
                                                        alt={reference.logo ? reference.logo.description : ''}
                                                    />
                                                }
                                            </a>
                                        </div>
                                    </header>
                                    <p className="block font-normal f5 text-align-center">{reference.field}</p>
                                </li>
                            )
                        })
                        : references.filter(reference => reference.category === currentCategory).map(reference => {
                            return (
                                <li key={reference.id} className="flex-col w-40-per-md w-full-xs flex-grow-1 w-20-per w-half-md bg-white py-6 px-4 mx-4 br-2 text-blue-dark shadow-1 my-5 max-width-300-px max-wdith-100-per-xs">
                                    <header className="flex flex-col items-center justify-center py-2">
                                        <p className="font-bold f4 text-blue-dark mt-0 mb-2">{reference.name}</p>
                                        <p className="font-normal f6 mt-1 mb-2">{reference.mission}</p>
                                        <div className="w-full h-40 flex justify-center items-center p-5 overflow-hidden mt-4">
                                            <a className="block w-full" target="_blank" href={reference.link ? reference.link : '/'}>
                                                {reference.logo && reference.logo.fluid &&
                                                    <Img
                                                        className="w-full"
                                                        fluid={reference.logo.fluid}
                                                        alt={reference.logo ? reference.logo.description : ''}
                                                    />
                                                }
                                            </a>
                                        </div>

                                    </header>
                                    <p className="block font-normal f5 text-align-center">{reference.field}</p>
                                </li>
                            )
                        })}
            </ul>
        </section>
    )
}

export default TrackRecordsReferences